#school-list-back {
    border-radius: 5px;
}


#school-select-container {
    width: 100%;
   .schoolpicker{
        display: none; 
    }

    .school-select{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;


        .school-list-container{
            display: none;
            width: 100%;
            max-width: 350px;
            box-shadow: 0 6px 12px rgba(0,0,0,.175);
            border: 1px solid rgba(0,0,0,.15);
            border-radius: 5px;
            
        }

    }


    #school-list{
        padding-left: 0px;
        max-height: 200px;
        overflow: auto;

        li {
            list-style: none;
            padding-top: 5px;
            padding-bottom: 5px;

            &:hover {
                  background-color: #F4F3F3;
            }

            img {
                margin: 5px;
            }

            span {
                margin-left: 15px;
            }
        }

        img {
            width: 20px;
        }
    }

    .btn-select{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
        max-width: 350px;
        height: 50px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #ccc;

        li{
            list-style: none;
            float: left;
            padding-bottom: 0px;

            span {
                 margin-left: 15px;
            }
        }

        img {
            width: 20px;
        }

        &:hover{
            background-color: #F4F3F3;
            border: 1px solid transparent;
            box-shadow: inset 0 0px 0px 1px #ccc;

            li{
                margin-left: 0px;
            }
        }

        &:focus{
            outline:none;
        }

         &:after {
            font-family: 'FontAwesome';
            font-size: 15px;
            content: '\f107';
            color: #01a2db;
            font-weight: 700;
        }

        &.open {
            &:after {
                content: '\f106'; 
            }
        }    
    }   
}

