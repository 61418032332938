.uploader-zone-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;

    .uploader-zone-content {
        flex: 1;
    }

    .media-right {
        flex:1;
         img {
            background-color: #fff;
            border: 1px solid #d1d5d7;
            border-radius: 6px;
            margin-bottom: 10px;
            padding: 7px;
         }
    }
}

.documents-container {
    .documents-menu {
        padding-right: 32px;
        .list-group-item {
            cursor: pointer;
            border: none;
            border-radius: 8px;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .check {
                width: 16px;
                margin-left: 10px;
            }

            &.active {
                font-weight: 700;
                a {
                    color: $white !important;
                }
            }

            &:not(.active) {
                background-color: transparent;
            }

            &.bphi-item-comment {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                .error-message {
                    color: $brand-red;
                }
            }
        }
    }

    .documents-upload-container {
        padding-left: 32px;

        .documents-upload-area {
            .uploader {
                margin-top: 20px;
            }
        }
    }
}
