/*
   Project variables
   ========================================================================== */

$img-path: '/public/img/';
$svg-path: '/public/svg/';
$font-path: '/public/fonts/';
$icon-font-path: $font-path;

$screen-xlg-desktop: 1490px;
$main-gutter: 60px;
$main-gutter-lg: 20px;
$main-gutter-xlg: 30px;
$grid-float-breakpoint: 992px;

$block-shadow: 0 10px 60px 10px rgba(0, 0, 0, 0.08);

//== Colors
$brand-primary: #01a2db; // blue
$brand-secondary: #01a2db; // green
$brand-dark: #172e36; // blue
$brand-gray: #e3e6e6; // gray
$brand-dark-gray: #7d939b; //dark gray
$brand-red: #f6416b; // red
$brand-yellow : #f2eb0c; // yellow
$brand-black : #000000; // black
$brand-green : #3cdda0;
$white: #ffffff;
$orange: #FFB800;
$button-disabled-color: #E4E6E8;
$tertiary-blue: #F2FAFD;

  //== Scaffolding
$body-bg: #3cdda0; //Default green
// $body-bg: #10069F; //Blue
// $body-bg: #e41145; //Pantone
// $body-bg: #DC0C47; //Red
// $body-bg: #000000; //Black
// $body-bg: #ffffff; //White
// $body-bg: #7e3885; //Purple
// $body-bg: #ef7713; //Orange
// $body-bg: #931c4c; //Violet
$text-color: #172e36;
$link-color: $text-color;
$link-hover-color: $brand-primary;

//== Typography
$font-family-catamaran: 'Catamaran', Arial, Helvetica, sans-serif;
$font-family-sans-serif: $font-family-catamaran;
$font-size-base: 16px;
$headings-color: $brand-dark;
$headings-font-weight: 700;

$font-size-h1: 32px;
$font-size-h2: 26px;
$font-size-h3: 20px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$header-bg: #fff;

//== Navbar
//$navbar-height: 90px;
$navbar-default-color: #fff;
$navbar-default-bg: transparent;
$navbar-default-border: transparent;

$navbar-default-link-color: $navbar-default-color;
$navbar-default-link-hover-color: $brand-primary;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-bg: $navbar-default-link-hover-bg;
$navbar-default-link-active-color: $navbar-default-color;

//== Navbar toggle
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-toggle-icon-bar-bg: $brand-primary;
$navbar-default-toggle-border-color: transparent;

//== Navtabs
$nav-tabs-active-link-hover-color: $brand-dark;


//== Buttons
$btn-color: #fff;
$btn-default-color: #999;
$button-outline-variant-bg-color: transparent;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $btn-primary-bg;


//== Forms
$label-color: $text-color;
$input-height-base: 40px;
$input-bg: #fff;
$input-border: #e3e6e7;
$input-color: $text-color;
$input-color-placeholder: #45585e;
$input-group-addon-bg: #f6f7f7;

$ckbox-size: 18px;
$rdiobox-size: 18px;

$ckbox-rdio-focus-color: transparent;
$ckbox-rdio-label-line-height: 18px;
$ckbox-checked-font-size: 12px;
$ckbox-default-bg: #f6f7f7;
$ckbox-checked-bg: $ckbox-default-bg;
$ckbox-default-color: #fff;
$ckbox-default-border-color: #e3e6e6;//$brand-dark;
$ckbox-default-checked-color: $ckbox-default-border-color;

$ckbox-color: #fff;
$rdio-default-bg: #fff;
$rdio-default-border-color: $brand-dark;
$rdio-default-checked-bg: $brand-dark;
$rdio-default-checked-border-color: $rdio-default-border-color;
$rdio-border-color: $brand-primary;

//== Components
$border-radius-small: 0;
$border-radius-base: 6px;
$border-radius-large: 0;


//== Modals
$modal-md: 770px;


//== Progress bar
$progress-border-radius: 8px;
$progress-bg: $brand-gray;

//== Pagination
$pagination-color: $text-color;
$pagination-bg: #f6f7f7;
$pagination-border: $pagination-bg;
$pagination-hover-border: $pagination-bg;

$pagination-hover-color: #fff;
$pagination-hover-bg: $brand-dark;
$pagination-hover-border: $pagination-hover-bg;
$pagination-active-color: #fff;
$pagination-active-bg: $brand-dark;
$pagination-active-border: $pagination-active-bg;

//== Well
$well-bg: #f6f7f7;


$hr-border: $input-border;
