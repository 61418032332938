
.visa-main-content {
    margin-bottom: 40px;
    background-color: $white;
    border-radius: 8px;
    padding-bottom: 20px;
    // max-height: calc(100vh - 140px);
    // padding-top: 0px;
    // overflow-y: scroll;

    .well {
        background-color: $tertiary-blue;
    }

    .visa-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.visa-right-side {
    box-shadow: 0 3px 5px $body-bg;
    padding: 16px;
    margin-top: 0;
    margin-bottom: 10px;
    border-radius: 0;

    table {
        width: 100%;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        background: white;
        border-radius: 10px;
        overflow: hidden;
        border: 2px solid $body-bg;
        margin: 10 0;

        th, td {
            padding: 12px 15px;
            text-align: left;
            border: 1px solid #ddd
        }
    }
}


.visa-pending-block {
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    .service-check-list-container {
        display: flex;
        flex-direction: column;
        padding: 30px;
    }
    .service-check-list {
        background-color: $white;
    }
}

.visa-success-block {
    margin-bottom: 30px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: $block-shadow;

    .media-body {
        padding: 25px 15px 5px;
        width: auto;
    }
}
.visa-success-media {
    border-bottom: 1px solid #e8eaeb;

    &.no-border-bottom {
        border-bottom: 0;
    }

    .media-left div {
        height: 218px;
        overflow: hidden;
    }
}

.vsm-title {

    strong {
        margin-right: 8px;
    }
    span {
        color: $text-color;
        font-size: 16px;
        font-weight: 400;
    }
}
.vs-content {
    padding: 15px 20px;
}
.vsm-content {
    font-size: 14px;
}

.vs-text {
    color: $brand-dark;
    font-size: 16px;
}

.vi-tag {
    margin-bottom: 15px;
}
.vi-btn {
    min-width: 206px;
    margin-bottom: 8px;
}

.visa-list {
    border-radius: 6px;
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: $block-shadow;
}
.visa-list-item {
    padding: 15px;
    border-top: 1px solid #e8eaeb;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
}
.vli-name {
    float: left;
    margin-bottom: 8px;
    color: $brand-dark;
    font-weight: 700;
    line-height: 1;
}
.vli-file {
    display: inline-block;
    vertical-align: sub;
    line-height: 1;
    font-size: 14px;
    color: $brand-dark;
}
.vli-meta {
    float: right;

    .btn {
        padding-top: 9px;
        padding-bottom: 7px;
        margin-left: 15px;
        text-transform: uppercase;
        font-size: 10px;
        vertical-align: top;
    }
}
.vli-action {
    display: inline-block;

    .btn-primary {
        background-color: $brand-primary !important;
        cursor: default;
    }
}
.vli-dl {
    display: inline-block;
    margin-left: 5px;
    @include square(32px);
    border: 1px solid #d1d5d7;
    background: 50% 50% svg-url('dl.svg') no-repeat;
    text-decoration: none !important;
    border-radius: 6px;
    vertical-align: top;
    transition: all ease .5s;

    &:hover,
    &:focus {
        background-color: #e6e6e6;
    }
}

.visa-upgrade-now {
    background-color: $brand-dark-gray;
    padding: 0 90px 24px;
    margin-top: -36px;

    .si-actions {
        border-radius: 8px;
    }
}

.visa-upgrade-container {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #7D939B;
    border-radius: 10px;
    margin: 0 auto;
    float: none;

    .si-actions {
        width: 80%;
        border-radius: 10px;
        margin-bottom: 20px;

        a {
            width: 100%;
            text-align: center;
            margin: 20px;
        }
    }

    .upgrade-header {
        width: 80%;
        margin-top: 20px;
        text-align: left;
        display: flex;
        color: #f2fafd;

        .upgrade-header-left {
            margin-right: 20px;
        }

        .upgrade-header-left svg {
            path {
                fill: #f2fafd;
                stroke: #f2fafd;
            }
        }
    }

    .inline-editing {
        outline: 1px dotted $white;
    }
}

.uploader-disabled {
    &.dropzone {
        background-color: $brand-dark-gray;
    }

    .uploader-zone-content {
        pointer-events: none;
        user-select: none;
        cursor: not-allowed;
    }
    .fu-cell {
        background-color: $brand-dark-gray;
        cursor: not-allowed;

        label {
            color: $white;
        }

        svg {
            path {
                fill: $white
            }
        }
    }
}

.button-container {
    padding: 0 10px;
    margin-top: 16px;
}

.btn-visa-confirm {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 100%;
    svg {
        height: 24px;
        margin-bottom: -5px;
        margin-left: 5px;
    }
}

.docref-option-description {
    margin-top: 10px;
}

//DOCUMENTS UPLOAD
#visa-documents-container {
    #visa-documents-menu {
        padding-right: 32px;
        .list-group-item {
            cursor: pointer;
            border: none;
            border-radius: 8px;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .check {
                width: 16px;
                margin-left: 10px;
            }

            &.active {
                font-weight: 700;
            }

            &:not(.active) {
                background-color: transparent;
            }
        }
    }

    #visa-documents-upload-container {
        padding-left: 32px;
    }
}

@media (min-width: $screen-xlg-desktop) {
}

@media (max-width: $screen-tablet - 1) {

    .vli-name {
        float: none;
    }
    .vli-file {
        margin-bottom: 8px;
    }
    .vli-dl {
        margin-left: 0;
    }
    .vli-meta {
        float: none;

        .btn {
            margin-left: 0;
        }
    }
    .vli-action {
        float: right;
    }
}
@media (max-width: 612px) {

    .visa-success-media {

        .media-left {

            img {
                width: 100px;
            }
            div {
                height: auto;
            }
        }
        .media-body {
            display: block;
            padding: 10px;
        }
        .media-left {
            display: block;
            padding-right: 0;
            text-align: center;
        }
        .ci-item {
            display: block;
            margin-right: 0;
            text-align: center;
        }
        .ci-tag {
            display: block;
        }
        .vi-btn {
            min-width: inherit;
            width: 100%;
        }
    }

    .visa-upgrade-now {
        padding: 0 5px 24px;
        margin-top: -36px;

        .si-actions {
            border-radius: 0;
        }
    }

    .btn-visa-confirm {
        font-size: smaller;
    }
}
