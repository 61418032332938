@mixin zoom-effect($scale) {
  transition: transform 0.3s;
  -ms-transform: scale($scale);       /* IE 9 */
  -webkit-transform: scale($scale);   /* Safari 3-8 */
  transform: scale($scale);
  z-index: 12;
  position: relative;
}

@function is-dark($color) {
  $dark-colors: #10069F, #000000, #7e3885, #931c4c;
  @return index($dark-colors, $color) != null;
}

$inline-color: if(is-dark($body-bg), #FFFFFF, $brand-dark);

.ff-inline-border {
    padding: 20px;
    margin: 10px 0 10px 0;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, .125);

    p strong, h1, h2, h3, h4, h5 {
        color: $brand-primary;
    }
}

.ff-inline-proposition {
    p strong, h1, h2, h3, h4, h5 {
        color: #01A2DB;;
    }

    box-shadow: 0 3px 5px $body-bg;
    padding: 16px;
    margin-top: 0;
    border-radius: 0;
}

.ff-inline-bg, .ff-bg {
    background-color: lighten($brand-primary, 50%);
    h1, h2, h3, h4, h5 {
        color: $brand-primary;
    }
}


.dz-complete {
   img, canvas {
        transition: transform 0.3s;
        &:hover {
            -ms-transform: scale(6); /* IE 9 */
            -webkit-transform: scale(6); /* Safari 3-8 */
            transform: scale(6);
            z-index: 12;
            position: relative;
        }
   }
   canvas {
        max-height: 100px;
   }
}

.uploader-zone-container {
    .media-right img {
        transition: transform 0.3s;
        &:hover {
            @include zoom-effect(2); 
        }
    }
}

.docref-option-document img {
    transition: transform 0.3s;
    &:hover {
        @include zoom-effect(2); 
    }
}


.webinar-event-image {
    transition: transform 0.3s;
    &.zoomed {
        @include zoom-effect(3); 
    }
}

.ff-zoom {
  img {
    transition: transform 0.3s;

    &.zoomed {
        @include zoom-effect(1.7); 
    }
  }

  &.ff-zoom-right {
    img {
      &.zoomed {
        transform-origin: right center;
      }
    }
  }
}

.service-step1-video {
  transition: transform 0.3s;

  &.zoomed {
   @include zoom-effect(1.7); 
    transform-origin: right center;
  }
}

.ff-inline-school-bg, .ff-school-bg {
    background-color: lighten($body-bg, 40%) !important;
    color: $brand-dark !important;

    &.ff-inline-school-bg {
        color: $inline-color !important;
    }

    &.ff-time {
        color: $body-bg !important;
        background-color: inherit !important;
        border: 1px solid;
    }
    &.btn {
        color: $body-bg !important;
        &:hover {
            background-color: $body-bg !important;
            color: lighten($body-bg, 40%) !important;
        }
    }
}

.ff-step-title, .ff-info-1-common {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .btn-outline-ff {
        border: $body-bg 1px solid;
        color:  inherit;
        background-color: inherit !important;
        
        &:hover {
            background-color: $body-bg !important;
            color:#FFFFFF !important;
        }
    }

    .ff-step1-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        .paper-guide-and {
            position: relative;
            display: inline-block;
            &:before, &:after {
                content: "";
                position: absolute;
                top: 50%;
                width: 100%; 
                height: 1px;
                background-color: #758388;
            }
            &:before {
                left: 30px;
                width: 60px;
            }
            &:after {
                right: 30px;
                width: 60px;
            }
        }
    }

    .ff-step-title-content {
        display: flex;
        align-items: center;
        h1, h2, h3 {
            margin-bottom: 0 !important;
        }

        .clock-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 50px;
            height: 40px;

            svg {
                fill: $body-bg;
            }

            .text-inside {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 8px; 
                font-weight: bold;
                color: $body-bg;
            }

        }
    }
}

.ff-border {
    padding: 10px;
    border-radius: 5px;
}

.ff-external-link {
    min-width: 200px;
    margin-left: 20px;
}

.tickbox-editable p, .tickbox-editable-add p {
    margin-bottom: 5px !important;
}

.tickbox-editable-add {
    margin: 0 20px 0 20px;
}


.chk-with-information label:after {
    content: "\f05a";
    font: normal normal normal 14px / 1 FontAwesome;
    margin-left: 10px;
    font-weight: 900;
    font-style: normal;
    color: #01a2db;
    cursor: pointer;
}


.responsive-iframe {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
    height: 0;
    overflow: hidden;
}

.responsive-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 576px) { /* col-sm breakpoint */
    .ff-step-title, .ff-info-1-common {
        flex-direction: column;
    }
}